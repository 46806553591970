import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled, { Size } from 'styled';
import { Row, Col } from 'reactstrap';
/* images */
import icon from 'images/icon/icon.svg';

/* styled components */
const Bar = styled.footer`
  width: 100%;
  height: 125px;
  position: relative;
  background-color: ${({ theme }): string => theme.bg};
`;
const Aligner = styled(Row)`
  height: 100%;
  align-items: center;
`;
const Icon = styled.img`
  position: absolute;
  width: 75px;
  height: auto;
  bottom: 150%;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`;
const First = styled(Col)`
  @media ${Size.sm}{
    display: none;
  }
`;
const Middle = styled(Col)`
  text-align:center;
`;
const Last = styled(Col)`
  text-align: center;
`;
export const Footer: FC = () => {
  return (
    <Bar>
      <Aligner>
        <First lg={{ size: 3, offset: 1 }} md={{ size: 3, offset: 1 }} xl={{ size: 3, offset: 1 }} ><Link to="/privacy">Privacy Policy</Link></First>
        <Middle lg={{ size: 4, offset: 0 }} md={{ size: 4, offset: 0 }} xl={{ size: 4, offset: 0 }}>
          <Icon alt="Instituut Visage" src={icon} title="Icoon Instituut Visage" />
          <p>
            All Rights Reserved &nbsp;
            {new Date().getFullYear()}
            &nbsp;&copy;
          </p>
        </Middle>
        <Last
          lg={{ size: 3, offset: 1 }}
          md={{ size: 3, offset: 1 }}
          xl={{ size: 3, offset: 1 }}>
          BE 0531 953 839
        </Last>
      </Aligner>
    </Bar>
  );
};
