import { ICategory } from "./Categorie";

export const CategoriesData: ICategory[] = [
  {
    name: "Handverzorging",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Basis manicure", price: 25 },
          { name: "Gelish manicure", price: 40 },
          { name: "Rubberbase manicure", price: 45 },
          { name: "Verwijderen Gelish", price: 25 },
          { name: "Polygel op de natuurlijke nagel", price: 50 },
          { name: "Polygel met verlenging", price: 65 },
          { name: "Verwijderen polygel", price: 20 },
          { name: "Aanvullend: french / decoratie", price: 5 },
        ],
      },
    ],
  },
  {
    name: "Voetverzorging",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Medische pedicure", price: 38 },
          { name: "Medische pedicure met gelish", price: 55 },
          { name: "Gelish tenen", price: 35 },
        ],
      },
    ],
  },
  {
    name: "Suikerontharing",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Wenkbrauwen", price: 10 },
          { name: "Wenkbrauwen shapen", price: 15 },
          { name: "Wenkbrauwen, bovenlip en kin", price: 20 },
          { name: "Volledig gezicht", price: 23 },
          { name: "Oksels", price: 20 },
          { name: "Bovenlip of kin", price: 10 },
          { name: "Bikinilijn", price: 20 },
          { name: "Bikinilijn volledig", price: 35 },
          { name: "Onderbenen en knie", price: 30 },
          { name: "Volledige benen", price: 45 },
          { name: "Hoe meer je combineert hoe goedkoper!", price: 0 },
        ],
      },
    ],
  },
  {
    name: "Laserontharing",
    subCategories: [
      {
        name: "Gelaat",
        items: [
          { name: "Wenkbrauwen (tussenstuk)", price: 45 },
          { name: "Bovenlip", price: 50 },
          { name: "Kin", price: 50 },
          { name: "Bovenlip & kin", price: 90 },
          { name: "Wangen", price: 70 },
          { name: "Kaaklijn", price: 90 },
          { name: "Bakkebaarden", price: 60 },
          { name: "Hals", price: 70 },
          { name: "Volledig gelaat", price: 120 },
        ],
      },
      {
        name: "Lichaam",
        items: [
          { name: "Schouders", price: 110 },
          { name: "Schouders & rug", price: 230 },
          { name: "Bovenrug", price: 165 },
          { name: "Borstkas", price: 150 },
          { name: "Oksels", price: 95 },
          { name: "Onderarmen", price: 95 },
          { name: "Bovenarmen", price: 95 },
          { name: "Volledige armen", price: 175 },
          { name: "Navellijn", price: 60 },
          { name: "Sliprand", price: 90 },
          { name: "Bikinilijn", price: 95 },
          { name: "Bikinilijn streepje", price: 130 },
          { name: "Volledige bikinilijn met schaamstreek", price: 150 },
          { name: "Bilnaad", price: 60 },
          { name: "Onderbenen & knie", price: 195 },
          { name: "Bovenbenen", price: 145 },
          { name: "Volledige benen", price: 320 },
          { name: "Voetrug", price: 55 },
          { name: "Tenen/vingers", price: 30 },
          { name: "*Prijzen zijn per sessie", price: 0 },
        ],
      },
    ],
  },

  {
    name: "Tinten",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Wimpers kleuren", price: 12 },
          { name: "Wenkbrauwen kleuren", price: 12 },
          { name: "Wenkbrauwen epileren en kleuren", price: 20 },
        ],
      },
    ],
  },

  {
    name: "Permanent make-up",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Eyeliner infralash boven", price: 196 },
          { name: "Eyeliner infralash onder", price: 175 },
          { name: "Eyeliner infrahlash boven & onder", price: 350 },
          { name: "Powder brows", price: 370 },
        ],
      },
    ],
  },
  {
    name: "Gelaatsverzorging",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Make-up", price: 45 },
          { name: "Hydra 3HA (60 min)", price: 85 },
          { name: "Jeunesse (60 min)", price: 90 },
          { name: "DX Glow (60 min)", price: 98 },
          { name: "Anti-pigmentatie (60 min)", price: 90 },
          { name: "Luxe soin secret (80 min)", price: 159 },
        ],
      },
    ],
  },
  {
    name: "Superboost",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Gelaat", price: 170 },
          { name: "Gelaat en hals", price: 270 },
          { name: "Hals en décolleté", price: 270 },
          { name: "Acné behandeling", price: 80 },
          {
            name: "Kuur vanaf 5 behandelingen + 1 gratis (prijs per behandeling)",
            price: 170,
          },
        ],
      },
    ],
  },
  {
    name: "Lichaamsverzorging",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Lichaamspeeling", price: 40 },
          { name: "Mini-stone therapie", price: 65 },
          { name: "Hot-stone therapie", price: 75 },
          { name: "Spa-therapie", price: 120 },
          { name: "Anti-age-therapie", price: 140 },
          { name: "Special men-therapie", price: 120 },
          { name: "Supplement hoofdhuidmassage-voetmassage", price: 20 },
        ],
      },
    ],
  },
  {
    name: "Arrangementen",
    subCategories: [
      {
        name: "",
        items: [
          { name: "Huwelijksarrangement", price: 150 },
          { name: "Ook andere arrangementen zijn mogelijk!", price: 0 },
        ],
      },
    ],
  },
];
